@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.adobe.com/fonts/degular-variable");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "degular-variable", sans-serif;
  }
}

.heading-4xl {
  @apply text-head-sm-4xl md:text-head-sm-4xl lg:text-head-lg-4xl;
}

.heading-3xl {
  @apply text-head-sm-3xl md:text-head-sm-3xl lg:text-head-lg-3xl;
}

.heading-2xl {
  @apply text-head-sm-2xl md:text-head-sm-2xl lg:text-head-lg-2xl;
}

.heading-xl {
  @apply text-head-sm-xl md:text-head-sm-xl lg:text-head-lg-xl;
}

.heading-lg {
  @apply text-head-sm-lg md:text-head-sm-lg lg:text-head-lg-lg;
}

.heading-md {
  @apply text-head-sm-md md:text-head-sm-md lg:text-head-lg-md;
}

.heading-sm {
  @apply text-head-sm-sm md:text-head-sm-sm lg:text-head-lg-sm;
}

.heading-xs {
  @apply text-head-sm-xs md:text-head-sm-xs lg:text-head-lg-xs;
}

.fixed-plugin {
  @apply text-fixed-plugin-sm text-white md:text-fixed-plugin-lg lg:text-fixed-plugin-lg;
}

input.defaultCheckbox {
  color: white;
}

input.defaultCheckbox::before {
  content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.66662 10.115L12.7946 3.98633L13.7379 4.92899L6.66662 12.0003L2.42395 7.75766L3.36662 6.81499L6.66662 10.115Z' fill='white'/%3E%3C/svg%3E%0A");
  fill: currentColor;
  opacity: 0;
  height: 16px;
  width: 16px;
  top: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input.defaultCheckbox::before path {
  fill: currentColor;
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

.react-daterange-picker__inputGroup {
  display: none; /* Hide the date text */
}

.react-daterange-picker__range-divider {
  display: none;
}

.react-daterange-picker__wrapper {
  border: none !important;
}

.react-date-picker__inputGroup {
  display: none; /* Hide the date text */
}

.react-date-picker__range-divider {
  display: none;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-calendar {
  border: none !important;
  border-radius: 1rem !important;
  padding: 1rem;
}

.react-calendar button {
  border-radius: 4rem !important;
  margin-bottom: 0.1rem !important;
}

.custom-tooltip {
  background-color: #fff;
  padding: 10px;
  border: 1px solid var(--color-400);
  border-radius: 5px;
  line-height: 1.125rem;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: Lato, sans-serif;
  color: var(--color-400);
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 0px transparent inset !important;
  color: inherit !important;
  background-color: inherit !important;
}

input:-webkit-autofill::first-line {
  color: inherit !important;
}
